var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.initFunctions = function () {
        mena.main.onClickEvents();
    };

    mena.main.onClickEvents = function ( args ) {
        $( window ).load( function() {
            //put various on click functionalities here
            AOS.init({
                // Global settings:
                disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
                disableMutationObserver: false, // disables automatic mutations' detections (advanced)
                debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
                throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


                // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
                offset: 300, // offset (in px) from the original trigger point
                delay: 0, // values from 0 to 3000, with step 50ms
                duration: 400, // values from 0 to 3000, with step 50ms
                easing: 'ease-out-quart', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

            });

        });
    };

    mena.main.slickSlider = function ( args ) {

        var defaults = {};

        var options = $.extend( defaults, args );

        $( window ).load( function() {
            $( '#' + options.blockId + '' ).slick( options.options );
        });

    };

    mena.main.googleMap = function ( args ) {

        var defaults = {
            "zoom": 15,
            "scrollwheel": false,
			"draggable": false
        };

        var options = $.extend( defaults, args );

        $( window ).load( function() {

            var map = new google.maps.Map( document.getElementById( options.blockId ), {
                zoom: options.zoom,
                scrollwheel: options.scrollwheel,
			    draggable: options.draggable,
                center: new google.maps.LatLng( options.lat, options.lng ),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            var infowindow = new google.maps.InfoWindow({ maxWidth: 350 });

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng( options.lat, options.lng ),
                map: map
            });

            google.maps.event.addListener( marker, 'click', ( function( marker ) {
                return function() {
                    infowindow.setContent('<p class="mena-map__info">' + options.address + '</p>');
                    infowindow.open( map, marker );
                }
            })( marker ));

        });

    };

    return mena.main.initFunctions();

}($));
