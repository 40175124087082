var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.init = function () {
       // mena.main.stickyHeader();
        mena.main.mobileMenu();
        mena.main.blockHero();
    };

    mena.main.blockHero = function () {
        MicroModal.init({
            onClose: (modal) => handleModalClose(modal),
        });

        function handleModalClose(modal) {
            let ytIframe = document.querySelector("#" + modal.id + " iframe");

            if (ytIframe) {
                ytIframe.src = ytIframe.src;
            }
        }

        $(".hero__content-info").slick({
					arrows: false,
					slidesToShow: 3,
					slidesToScroll: 1,
					autoplay: true,
					dots: true,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2,
								infinite: true,
							},
						},
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2,
							},
						},
						{
							breakpoint: 440,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							},
						},
					],
				});
    }
    mena.main.executeFunctionsQueue = function ( key, queue_array ) {
        // Remove and execute all items in the array
        while ( queue_array[key].length > 0) {
            ( queue_array[key].shift() )();
        }
    }

    mena.main.loadScript = function( id, url, callback ) {

        // If external asset is already in DOM and loaded, we can execute the callback immediately
        if(typeof mena.assetsLoaded == 'undefined') {
            mena.assetsLoaded = [];
        }
        if(mena.assetsLoaded[id] === true) {
            callback();
            return;
        }

        if(typeof mena.functionsQueue == 'undefined') {
            mena.functionsQueue = [];
        }
        if(typeof mena.functionsQueue[id] == 'undefined') {
            mena.functionsQueue[id] = [];
        }
        mena.functionsQueue[id].push( callback );

        //Check if script exists. If not, create it, if yes, we have already added it in the queue and we're all
        var assetExists = document.getElementById( id );
        if( null == assetExists ) {
            // Adding the script tag to the head as suggested before
            var body = document.getElementsByTagName( 'body' )[0];
            var script = document.createElement( 'script' );
            script.type = 'text/javascript';
            script.src = url;
            script.id = id;

            // Then bind the event to the callback function.
            // There are several events for cross browser compatibility.
            script.onload = function() {
                mena.assetsLoaded[id] = true;
                mena.main.executeFunctionsQueue( id, mena.functionsQueue );
            }
            script.onreadystatechange = function() {
                mena.assetsLoaded[id] = true;
                mena.main.executeFunctionsQueue( id, mena.functionsQueue );
            }

            // Fire the loading
            body.appendChild( script );
        }

    };

    mena.main.debounce = function(func, wait, immediate) {

        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };

    };

    mena.main.stickyHeader = function() {

        var stickyHeader = function() {
            var header = $( '#header' );
            $( '#content' ).css( "margin-top", header.outerHeight() );

            if ( $( window ).scrollTop() > 1 ) {
                header.addClass( 'sticky' );
            } else {
                header.removeClass( 'sticky' );
            }
        }

        window.addEventListener('load', stickyHeader);
        window.addEventListener('scroll', mena.main.debounce( stickyHeader, 150 ));
        window.addEventListener('resize', mena.main.debounce( stickyHeader, 150 ));

    };

    mena.main.mobileMenu = function() {

        var respMenu = $( '.resp-menu' );
        var button   = $( '.js-menu-toggle' );
        var body     = $( 'body' );

        button.click(function(e) {
            body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        });

        /* Sliding side menu, close on swipe */
        if ($().swipe) {
            respMenu.swipe({
                swipeRight: function(event, direction, distance, duration, fingerCount) {
                    body.removeClass('menu-open');
                },
                threshold: 20,
                excludedElements: ""
            });
            button.swipe({
                swipeLeft: function(event, direction, distance, duration, fingerCount) {
                    body.addClass('menu-open');
                },
                threshold: 20,
                excludedElements: ""
            });
        }

        /* Sliding side menu submenu */
        respMenu.find('.menu-item > a').click(function(e) {
            if ($(this).attr('href') == "#") {
                e.preventDefault();
                $('.sub-menu').not($(this).siblings()).slideUp(300);
                $(this).siblings('.sub-menu').slideToggle(300);
            }
        });

        /* Adding submenu arrows to the responsive navigation */
        respMenu.find('.sub-menu').each(function() {
            $(this)
							.parent()
							.append(
								'<a class="submenu-button" href="javascript:void(0)"><svg class="arrow-down" width="15px" height="8px"><polygon fill=#d91e41" points="15.002,0 7.501,7.501 0,0 "></polygon></svg></a>'
							);
        });

        $('.submenu-button').click(function() {
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).prev('.sub-menu').slideToggle(100);
            $(this).toggleClass('rotate-arrow');
        });

    };

return mena.main.init();

}($));
